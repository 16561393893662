import { ref, watch, computed } from '@vue/composition-api'
import http from '@axios'
import Swal from 'sweetalert2'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'username',
      label: 'Имя пользователь',
      sortable: true,
    },
    {
      key: 'email',
      label: 'Эл. почта',
      sortable: true,
    },
    {
      key: 'organization',
      label: 'Организация',
      sortable: false,
    },
    {
      key: 'phone',
      label: 'Телефон',
      sortable: false,
    },
    {
      key: 'status',
      label: 'Статус',
      sortable: true,
    },
    {
      key: 'created_at',
      label: 'Дата рег.',
      sortable: false,
    },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchUsers = (ctx, callback) => {
    const qs = require('qs');
    http
      .get('https://edu.startupchoikhona.tj/backend/api/user/recent-students', {
        params: {
          search: searchQuery.value,
          length: perPage.value,
          page: currentPage.value,
          order: {
            column: sortBy.value,
            type: isSortDirDesc && !isSortDirDesc.value ? 'asc' : 'desc',
          },
        },
        paramsSerializer: params => {
          return qs.stringify(params)
        }
      })
      .then(response => {
        const users = response.data.data
        callback(users)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserStatusVariant = status => {
    if (status === 2) return 'warning'
    if (status === 1) return 'success'
    if (status === 0) return 'secondary'
    return 'primary'
  }

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserStatusVariant,
    refetchData,

  }
}
