<template>
  <div>
    <b-row>
      <b-col>
        <statistics-cart/>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <activity-log/>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <chart-topics/>
      </b-col>
      <b-col md="6">
        <chart-students/>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <recent-students/>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import StatisticsCart from './StatisticsCart.vue'
import ActivityLog from './ActivityLog.vue'
import ChartTopics from '@/views/dashboard/home/ChartTopics.vue'
import ChartStudents from '@/views/dashboard/home/ChartStudents.vue'
import RecentStudents from '@/views/dashboard/home/RecentStudents.vue'

export default {
  components: {
    RecentStudents,
    ChartStudents,
    BRow,
    BCol,
    ChartTopics,
    StatisticsCart,
    ActivityLog,
  },
  data() {
    return {}
  },
}
</script>

<style>
</style>
