<template>

  <div>
    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <b-card-title class="mb-25">
            Последняя регистрация студентов
          </b-card-title>
        </b-row>

      </div>

      <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchUsers"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Записи не найдены"
          :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(username)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                  size="32"
                  :src="data.item.photo ? 'https://edu.startupchoikhona.tj/backend/'+data.item.photo : ''"
                  :text="avatarText(data.item.name)"
                  :variant="`light-success`"
                  :to="'/'"
              />
            </template>
            <span
                class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </span>
            <small class="text-muted">{{ data.item.username }}</small>
          </b-media>
        </template>

        <!-- Column: Organization -->
        <template #cell(organization)="data">
            <span class="align-text-top">{{ data.item.organization }}</span>
        </template>

        <!-- Column: Phone -->
        <template #cell(phone)="data">
            <span class="align-text-top">{{ data.item.phone }}</span>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
              class="text-capitalize"
          >
            {{ statusTranslate[data.item.status] }}
          </b-badge>
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BCardTitle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useUsersList from './useUsersList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardTitle,

    vSelect,
  },
  created() {},
  setup() {
    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      {
        label: 'Администратор',
        value: '1',
      },
      {
        label: 'Студент',
        value: '2',
      },
      {
        label: 'Ментор',
        value: '3',
      },
    ]

    const roleTranslate = {
      admin: 'Администратор',
      student: 'Студент',
      teacher: 'Ментор',
    }
    const statusTranslate = {
      0: 'Неактивный',
      1: 'Активный',
      2: 'В ожидание',
    }

    const statusOptions = [
      {
        label: 'В ожидание',
        value: '2',
      },
      {
        label: 'Активный',
        value: '1',
      },
      {
        label: 'Неактивный',
        value: '0',
      },
    ]

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      resolveUserStatusVariant,

    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      resolveUserStatusVariant,
      statusOptions,
      statusTranslate,

    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
