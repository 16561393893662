<template>
  <div>
    <b-row class="match-height">
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UsersIcon"
          :statistic="countStudent"
          statistic-title="Студенты"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UsersIcon"
          color="success"
          :statistic="countMentor"
          statistic-title="Менторы"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="BriefcaseIcon"
          color="primary"
          :statistic="countCourse"
          statistic-title="Курсы"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="CheckSquareIcon"
          color="success"
          :statistic="countGetCertificate"
          statistic-title="Получили сертификат"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BRow,
    BCol,
    StatisticCardHorizontal,
  },
  data() {
    return {
      countStudent: 0,
      countMentor: 0,
      countCourse: 0,
      countGetCertificate: 0,
    }
  },
  created() {
    this.$http.get('https://edu.startupchoikhona.tj/backend/api/dashboard/statistic-cards', {})
      .then(response => {
        const { data } = response.data
        this.countStudent = data.countStudent
        this.countMentor = data.countMentor
        this.countCourse = data.countCourse
        this.countGetCertificate = data.countGetCertificate
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Ошибка',
            icon: 'XCircleIcon',
            variant: 'danger',
            text: error.data.message,
          },
        })
      })
  },
}
</script>

<style>
</style>
