<template>
  <b-card>
    <b-card-title class="mb-1">
      Обзор тематики
    </b-card-title>

    <vue-apex-charts
      type="donut"
      height="350"
      :options="donutChart.chartOptions"
      :series="donutChart.series"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
  },
  data() {
    return {
      donutChart: {
        series: [85, 16, 50, 50],
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          colors: [
            '#ffe700',
            '#00d4bd',
            '#826bf8',
            '#2b9bf4',
            '#FFA1A1',
          ],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '2rem',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return ''
                    },
                  },
                  total: {
                    show: true,
                    fontSize: '1.5rem',
                    label: '',
                    formatter() {
                      return ''
                    },
                  },
                },
              },
            },
          },
          labels: ['Модуль', 'Раздел', 'Тема', 'Тест'],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
    }
  },
  created() {
    this.$http.get('https://edu.startupchoikhona.tj/backend/api/dashboard/topics', {})
      .then(response => {
        const { data } = response.data
        this.donutChart.series = [data.countModule, data.countHead, data.countLesson, data.countTest]
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Ошибка',
            icon: 'XCircleIcon',
            variant: 'danger',
            text: error.data.message,
          },
        })
      })
  },
}
</script>
